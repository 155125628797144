import React, { FC } from 'react'
import styles from './BodyHeader.module.scss'
import Rates from './Rates'
import Link from 'next/link'
import HeaderLogoSvg from '../../public/icons/logo-header.svg'
import Forum from '../../public/icons/forum-icon.svg'
import { LayoutAuth } from './LayoutAuth/LayoutAuth'
import cn from 'classnames'
// import GigaChat from '../common/GigaChat'

interface IPropsBody {
  posY: number
  isWhite?: boolean
}

export const BodyHeader: FC<IPropsBody> = ({ posY, isWhite }) => {
  return (
    <div className={cn(styles.middle, posY > 80 && styles.withoutLine)}>
      <div className={styles.rates}>
        <Rates />
      </div>
      <Link
        className={cn(styles.logo, isWhite && styles['logo--white'])}
        href="/"
        aria-label="На главную"
      >
        <HeaderLogoSvg />
      </Link>
      <div className={styles.buttons}>
        {/* <GigaChat
          classNames={{
            container: styles.chat,
            button: isWhite && styles.whiteGigaChat,
          }}
        /> */}
        <Link
          href="https://t.me/forum_code"
          className={cn(styles.forum, isWhite && styles['forum--white'])}
          target="_blank"
        >
          Форум
          <Forum />
        </Link>
        <LayoutAuth
          className={cn(styles.auth, isWhite && styles['auth--white'])}
        />
      </div>
    </div>
  )
}
