export default {
  'kod-ai-in-russia': {
    id: 'kod-ai-in-russia',
    exclusive: {
      img: '/images/specials/kod-ai-in-russia/tablet.jpg',
    },
    asideData: {
      title: 'искусственный интеллект',
      date: 'Ноябрь 2024',
      number: 1,
    },
    mainPost: {
      images: {
        desc: '/images/specials/kod-ai-in-russia/tablet.jpg',
        mobile: '/images/specials/kod-ai-in-russia/mobile.jpg',
      },
      title: 'Код<br>искусственного интеллекта в&nbsp;России',
      subtitle: 'Яндекс, билайн, МТС, Сбер, AIRI',
      author: {
        name: 'Пётр Клинский',
        slug: 'piotr',
        picture: {
          url: 'https://kod.ru/content/images/2024/10/DALL-E-2024-10-21-10.44.33---Pixel-art-style-avatar-for-a-30-year-old-article-author--featuring-a-simple-and-minimalistic-design.-The-character-should-have-a-neutral-expression--s.png',
          id: 'piotr',
        },
      },
      readTime: 9,
      views: 324,
    },
    images: {
      desc: '/images/specials/kod-ai-in-russia/desc.jpg',
      tablet: '/images/specials/kod-ai-in-russia/tablet.jpg',
      mobile: '/images/specials/kod-ai-in-russia/mobile.jpg',
    },
    tags: [
      { slug: 'ai', name: 'Ai' },
      { slug: 'in', name: 'Интервью' },
    ],
    main: {
      title:
        'Искусственный интеллект&nbsp;&mdash; это шанс вернуть&nbsp;РФ статус мировой технологической сверхдержавы',
      subtitle:
        'С&nbsp;этим трудно спорить, а&nbsp;самое интересное заключается в&nbsp;том, что это совершенно точно описывает все, что происходит сейчас в&nbsp;России в&nbsp;сфере искусственного интеллекта.',
    },
    author: {
      name: 'Пётр Клинский',
      slug: 'piotr',
      picture: {
        url: 'https://kod.ru/content/images/2024/10/DALL-E-2024-10-21-10.44.33---Pixel-art-style-avatar-for-a-30-year-old-article-author--featuring-a-simple-and-minimalistic-design.-The-character-should-have-a-neutral-expression--s.png',
        id: 'piotr',
      },
    },
  },
  'makhov-code-a-story-that-inspires': {
    id: 'makhov-code-a-story-that-inspires',
    exclusive: {
      img: '/images/specials/makhov-code-a-story-that-inspires/tablet.jpg',
    },
    asideData: {
      title: 'Код Махова',
      date: 'Декабрь 2024',
      number: 2,
    },
    mainPost: {
      images: {
        desc: '/images/specials/makhov-code-a-story-that-inspires/post-tablet.jpg',
        mobile:
          '/images/specials/makhov-code-a-story-that-inspires/post-mobile.jpg',
      },
      title: 'КОД<br>МАХОВА',
      subtitle:
        'Успех — это всегда десятки поражений,\n после которых вас ждёт победа.',
      author: {
        name: 'Гайк Даллакян',
        slug: 'aik',
        picture: {
          url: 'https://kod.ru/content/images/2024/09/IMG_4777-1.JPG',
          id: 'aik',
        },
      },
    },
    images: {
      desc: '/images/specials/makhov-code-a-story-that-inspires/desc.jpg',
      tablet: '/images/specials/makhov-code-a-story-that-inspires/tablet.jpg',
      mobile: '/images/specials/makhov-code-a-story-that-inspires/mobile.jpg',
    },
    tags: [],
    main: {
      title: 'КОД МАХОВА<br> ИСТОРИЯ, КОТОРАЯ ВДОХНОВЛЯЕТ ',
      subtitle: `Успех — это всегда десятки поражений, после которых вас ждёт победа.
      Говорим с Владимиром Маховым — серийным предпринимателем, основателем TON Dating,
      TON Diamonds и сооснователем TON Apps и Viral Talent Management.`,
    },
    author: {
      name: 'Гайк Даллакян',
      slug: 'aik',
      picture: {
        url: 'https://kod.ru/content/images/2024/09/IMG_4777-1.JPG',
        id: 'aik',
      },
    },
  },
}
