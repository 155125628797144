import { FC, useContext } from 'react'
import Link from 'next/link'

import styles from './MobileTop.module.scss'
import SearchIcon from '../../public/icons/search.svg'
import Logo from './icons/header-mobile-logo.svg'
import Burger from '../../public/icons/burger.svg'
import CloseSvg from '../../public/icons/close.svg'
import MaxWidth from './MaxWidth'
import cn from 'classnames'
import { LayoutContext } from '../../contexts/LayoutContext'

interface IMobileTopProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  className?: string
}

const MobileTop: FC<IMobileTopProps> = ({
  open,
  setOpen,
  setShowSearchBar,
  className,
}) => {
  const { isScrollingDown } = useContext(LayoutContext)

  const handlerShowSearchBar = () => {
    setShowSearchBar((prev) => !prev)
  }

  return (
    <div
      className={cn(
        styles.wrapper,
        isScrollingDown && styles['wrapper--hidden'],
        className
      )}
    >
      <MaxWidth className={styles.row}>
        <button
          className={styles.search}
          type="button"
          onClick={handlerShowSearchBar}
          aria-label="Поиск"
        >
          <SearchIcon />
        </button>
        <Link className={styles.logo} href="/" aria-label="На главную">
          <Logo />
        </Link>
        <button
          className={styles.burger}
          type="button"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          aria-label={open ? 'Закрыть меню' : 'Открыть меню'}
        >
          {open ? <CloseSvg /> : <Burger />}
        </button>
      </MaxWidth>
    </div>
  )
}

export default MobileTop
