import React, { FC, createContext, useState } from 'react'

interface ILayoutContext {
  isScrollingDown: boolean
  setIsScrollingDown: React.Dispatch<React.SetStateAction<boolean>>
}

export const LayoutContext = createContext({} as ILayoutContext)

const LayoutProvider: FC = ({ children }) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false)

  return (
    <LayoutContext.Provider value={{ isScrollingDown, setIsScrollingDown }}>
      {children}
    </LayoutContext.Provider>
  )
}
export default LayoutProvider
